import { css, cva, cx } from '../styled-system/css';

import { SystemStyleObject } from '../styled-system/types';
import { forwardRef } from 'react';

export type HtmlDivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const box = cva({
  base: {},
  defaultVariants: { layout: 'none', scroll: 'none' },
  variants: {
    layout: {
      none: {},
      flexRow: {
        display: 'flex',
        flexDirection: 'row',
      },
      flexColumn: {
        display: 'flex',
        flexDirection: 'column',
      },
      centeredRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    scroll: {
      none: {},
      auto: { overflow: 'auto' },
      hidden: { overflow: 'hidden' },
      subtle: {
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
  },
});

type BoxVariants = Parameters<typeof box>[0];

type IncomingBoxProps = {
  children?: React.ReactNode;
  css?: SystemStyleObject;
  as?: React.ElementType;
} & BoxVariants &
  HtmlDivProps;
export type BoxProps = React.ComponentProps<typeof Box>;

export const Box = forwardRef<HTMLDivElement, IncomingBoxProps>(
  ({ as: Tag = 'div', children = null, className, css: cssObject = {}, layout, scroll, ...props }, ref) => {
    return (
      <Tag className={cx(className, box({ layout, scroll }), css(cssObject))} ref={ref} {...props}>
        {children}
      </Tag>
    );
  }
);

Box.displayName = 'Box';
